import { FunctionComponent } from "react";
import { FormattedMessage, FormattedDate } from "react-intl";

import useApp from "../hooks/useApp";


const DateRangeOutlet: FunctionComponent = () => {
  const { dateRange } = useApp();

  return (
    typeof dateRange == "string" ? (
      <FormattedMessage id={`common.analytics.date-range.${dateRange}`} />
    ) : (
      <>
        <FormattedDate value={dateRange.from.toDate()} />
        {" - "}
        <FormattedDate value={dateRange.to.toDate()} />
      </>
    )
  )
}

export default DateRangeOutlet;