import { IsOptional, IsString, IsUrl } from "class-validator";

import type { JsonArray } from "@namedicinu/internal-types";

import { WebEntry } from "./webEntry";

import {
  IsAsset,
  IsLocalizedBoolean,
  IsLocalizedInfoPanel,
  IsLocalizedMarkdown,
  IsLocalizedString,
  IsLocalizedStringArray,
} from "../helpers/decorators";
import { Transform } from "class-transformer";
import { Localized, transformLocalized } from "@namedicinu/internal-types";

export class Faculty extends WebEntry {
  public get type() {
    return "faculty" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  shortTitle: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
  @IsString()
  country: string;
  @Transform(transformLocalized)
  @IsLocalizedString()
  shortDescription: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  description: Localized<string>;
  @IsAsset("image")
  image: string;
  @IsOptional()
  @IsUrl()
  website?: string;
  @Transform(transformLocalized)
  @IsLocalizedStringArray()
  overview: Localized<string[]>;
  @Transform(transformLocalized)
  @IsLocalizedInfoPanel()
  descriptionPanel: Localized<JsonArray>;
  @Transform(transformLocalized)
  @IsLocalizedBoolean()
  dentistry: Localized<boolean>;
  @Transform(transformLocalized)
  @IsLocalizedBoolean()
  oralInterview: Localized<boolean>;
}
