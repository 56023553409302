import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { Settings, User, Users, CheckSquare, AlertOctagon, Package, Image, ArrowUpRight, LogIn, Mail, FileText, AlertTriangle, BarChart2 } from "react-feather";
import { RouteObject } from "react-router-dom";

import AdminWrapper from "./components/AdminWrapper";
import { Module } from "../../types";

const AdminIndexPage = lazy(() => import("./pages/AdminIndexPage"));
const AdminUsersPage = lazy(() => import("./pages/AdminUsersPage"));
const AdminQuizReleasePage = lazy(() => import("./pages/AdminQuizReleasePage"));
const AdminCourseRegistrationsPage = lazy(() => import("./pages/AdminCourseRegistrationsPage"));
const AdminUserReferenceClaimsPage = lazy(() => import("./pages/AdminUserReferenceClaimsPage"));
const AdminStudyGroupsPage = lazy(() => import("./pages/AdminStudyGroupsPage"));
const AdminStudyGroupStudentsPage = lazy(() => import("./pages/AdminStudyGroupStudentsPage"));

const AdminWebDeploymentPage = lazy(() => import("./pages/web/AdminWebDeploymentPage"));
const AdminWebAssetPage = lazy(() => import("./pages/web/AdminWebAssetPage"));
const AdminWebBlogPage = lazy(() => import("./pages/web/AdminWebBlogPage"));
const AdminWebFacultyPage = lazy(() => import("./pages/web/AdminWebFacultyPage"));
const AdminWebFacultyStudentsPage = lazy(() => import("./pages/web/AdminWebFacultyStudentsPage"));
const AdminWebFacultyFieldPage = lazy(() => import("./pages/web/AdminWebFacultyFieldPage"));
const AdminWebLecturerPage = lazy(() => import("./pages/web/AdminWebLecturerPage"));
const AdminWebPaymentFrequencyPage = lazy(() => import("./pages/web/AdminWebPaymentFrequencyPage"));
const AdminWebProductPage = lazy(() => import("./pages/web/AdminWebProductPage"));
const AdminWebProductVariationPage = lazy(() => import("./pages/web/AdminWebProductVariationPage"));
const AdminWebQuizQuestionPage = lazy(() => import("./pages/web/AdminWebQuizQuestionPage"));
const AdminWebSiteInformationPage = lazy(() => import("./pages/web/AdminWebSiteInformationPage"));
const AdminWebStudyFieldPage = lazy(() => import("./pages/web/AdminWebStudyFieldPage"));
const AdminWebTermPage = lazy(() => import("./pages/web/AdminWebTermPage"));
const AdminWebTestimonialPage = lazy(() => import("./pages/web/AdminWebTestimonialPage"));

const AdminUsagePage = lazy(() => import("./pages/AdminUsagePage"));
const AdminGeneratorPage = lazy(() => import("./pages/AdminGeneratorPage"));
const AdminIssueReportsPage = lazy(() => import("./pages/AdminIssueReportsPage"));
const AdminUserAbusePage = lazy(() => import("./pages/AdminUserAbusePage"));
const AdminCategoriesPage = lazy(() => import("./pages/AdminCategoriesPage"));
const AdminStatsPage = lazy(() => import("./pages/AdminStatsPage"));
const AdminTasksPage = lazy(() => import("./pages/AdminTasksPage"));
const AdminEmailsPage = lazy(() => import("./pages/AdminEmailsPage"));

const tabs = [
  {
    id: "sep-0",
    name: (
      <div className="titled-hr">
        <hr/>
        <span><FormattedMessage id="module.admin.core" defaultMessage="Core" /></span>
      </div>
    ),
  },
  {
    id: "quiz-release",
    name: <div className="d-flex align-items-center gap-2">
      <CheckSquare />
      <FormattedMessage id="module.admin.quizRelease" defaultMessage="Quiz Release" />
    </div>,
    element: <AdminQuizReleasePage />,
  },
  {
    id: "users",
    name: <div className="d-flex align-items-center gap-2">
      <User />
      <FormattedMessage id="module.admin.users" defaultMessage="Users" />
    </div>,
    element: <AdminUsersPage />,
  },
  {
    id: "course-registrations",
    name: <div className="d-flex align-items-center gap-2">
      <LogIn />
      <FormattedMessage id="module.admin.courseRegistrations" defaultMessage="Course registrations" />
    </div>,
    element: <AdminCourseRegistrationsPage />,
  },
  {
    id: "reference-user-claims",
    name: <div className="d-flex align-items-center gap-2">
      <Users />
      <FormattedMessage id="module.admin.userReferenceClaims" defaultMessage="User reference claims" />
    </div>,
    element: <AdminUserReferenceClaimsPage />,
  },
  {
    id: "study-groups",
    name: <div className="d-flex align-items-center gap-2">
      <Package />
      <FormattedMessage id="module.admin.studyGroups" defaultMessage="Study Groups" />
    </div>,
    element: <AdminStudyGroupsPage />,
  },
  {
    id: "issue-reports",
    name: <div className="d-flex align-items-center gap-2">
      <AlertTriangle />
      <FormattedMessage id="module.admin.issueReports" defaultMessage="Issue Reports" />
    </div>,
    element: <AdminIssueReportsPage />,
  },
  {
    id: "sep-1",
    name: (
      <div className="titled-hr">
        <hr/>
        <span><FormattedMessage id="module.admin.web" defaultMessage="Web" /></span>
      </div>
    ),
  },
  {
    id: "web-deployment",
    name: <div className="d-flex align-items-center gap-2">
      <ArrowUpRight />
      <FormattedMessage id="module.admin.deployment" defaultMessage="Deployment" />
    </div>,
    element: <AdminWebDeploymentPage />
  },
  {
    id: "web-asset",
    name: <div className="d-flex align-items-center gap-2">
      <Image />
      <FormattedMessage id="module.admin.assets" defaultMessage="Assets" />
    </div>,
    element: <AdminWebAssetPage />
  },
  {
    id: "web-blog",
    name: <FormattedMessage id="module.admin.web.blog" defaultMessage="Blog" />,
    element: <AdminWebBlogPage />,
  },
  {
    id: "web-faculty",
    name: <FormattedMessage id="module.admin.web.faculty" defaultMessage="Faculty" />,
    element: <AdminWebFacultyPage />,
  },
  {
    id: "web-faculty-students",
    name: <FormattedMessage id="module.admin.web.facultyStudents" defaultMessage="Faculty Students" />,
    element: <AdminWebFacultyStudentsPage />,
  },
  {
    id: "web-field",
    name: <FormattedMessage id="module.admin.web.facultyField" defaultMessage="Field" />,
    element: <AdminWebFacultyFieldPage />,
  },
  {
    id: "web-lecturer",
    name: <FormattedMessage id="module.admin.web.lecturer" defaultMessage="Lecturer" />,
    element: <AdminWebLecturerPage />,
  },
  {
    id: "web-payment-frequency",
    name: <FormattedMessage id="module.admin.web.paymentFrequency" defaultMessage="Payment Frequency" />,
    element: <AdminWebPaymentFrequencyPage />,
  },
  {
    id: "web-product",
    name: <FormattedMessage id="module.admin.web.product" defaultMessage="Product" />,
    element: <AdminWebProductPage />,
  },
  {
    id: "web-productVariation",
    name: <FormattedMessage id="module.admin.web.productVariation" defaultMessage="Product Variation" />,
    element: <AdminWebProductVariationPage />,
  },
  {
    id: "web-quiz-question",
    name: <FormattedMessage id="module.admin.web.quizQuestion" defaultMessage="Quiz Question" />,
    element: <AdminWebQuizQuestionPage />,
  },
  {
    id: "web-site-information",
    name: <FormattedMessage id="module.admin.web.siteInformation" defaultMessage="Site Information" />,
    element: <AdminWebSiteInformationPage />,
  },
  {
    id: "web-study-field",
    name: <FormattedMessage id="module.admin.web.studyField" defaultMessage="Study Field" />,
    element: <AdminWebStudyFieldPage />,
  },
  {
    id: "web-term",
    name: <FormattedMessage id="module.admin.web.term" defaultMessage="Term" />,
    element: <AdminWebTermPage />,
  },
  {
    id: "web-testimonial",
    name: <FormattedMessage id="module.admin.web.testimonial" defaultMessage="Testimonial" />,
    element: <AdminWebTestimonialPage />,
  },
  {
    id: "sep-2",
    name: (
      <div className="titled-hr">
        <hr/>
        <span><FormattedMessage id="module.admin.utils" defaultMessage="Utils" /></span>
      </div>
    ),
  },
  {
    id: "usage",
    name: <div className="d-flex align-items-center gap-2">
      <BarChart2 />
      <FormattedMessage id="module.admin.usage" defaultMessage="Usage" />
    </div>,
    element: <AdminUsagePage />,
  },
  {
    id: "generator",
    name: <div className="d-flex align-items-center gap-2">
      <FileText />
      <FormattedMessage id="module.admin.generator" defaultMessage="Generator" />
    </div>,
    element: <AdminGeneratorPage />,
  },
  {
    id: "user-abuse",
    name: <div className="d-flex align-items-center gap-2">
      <AlertOctagon />
      <FormattedMessage id="module.admin.userAbuse" defaultMessage="User Abuse" />
    </div>,
    element: <AdminUserAbusePage />,
  },
  {
    id: "study-group-students",
    name: <div className="d-flex align-items-center gap-2">
      <Package />
      <FormattedMessage id="module.admin.studyGroupStudents" defaultMessage="Study Group Students" />
    </div>,
    element: <AdminStudyGroupStudentsPage />,
  },
  {
    id: "categories",
    name: <FormattedMessage id="module.admin.categories" defaultMessage="Categories" />,
    element: <AdminCategoriesPage />,
  },
  {
    id: "stats",
    name: <FormattedMessage id="module.admin.stats" defaultMessage="Stats" />,
    element: <AdminStatsPage />,
  },
  {
    id: "tasks",
    name: <div className="d-flex align-items-center gap-2">
      <Package />
      <FormattedMessage id="module.admin.tasks" defaultMessage="Tasks" />
    </div>,
    element: <AdminTasksPage />,
  },
  {
    id: "emails",
    name: <div className="d-flex align-items-center gap-2">
      <Mail />
      <FormattedMessage id="module.admin.emails" defaultMessage="Emails" />
    </div>,
    element: <AdminEmailsPage />,
  },
];

export default {
  route: {
    path: "/admin",
    element: <AdminWrapper tabs={tabs} />,
    children: [
      {
        index: true,
        path: "/admin",
        element: <AdminIndexPage />,
      },
      ...tabs.map((tab) => (tab.element ? (
        {
          path: `/admin/${tab.id}`,
          element: tab.element,
        }
      ) : undefined)).filter(Boolean) as RouteObject[],
    ],
  },
  allowed: (userView) => userView.modules.admin,
  name: <FormattedMessage id="module.admin.name" defaultMessage="Admin" />,
  icon: <Settings />,
} satisfies Module;
