import * as yup from "yup";
import { IsArray, IsEnum, IsInt, IsOptional, IsString, IsUUID, ValidateNested } from "class-validator";
import { type Moment } from "moment";
import { Transform, Type } from "class-transformer";

import { IsMoment, IsYup } from "../helpers/decorators";
import { transformMoment } from "../helpers/transforms";
import { yupRecord } from "../schemas/record";

export const emailStates = ["PENDING", "SUCCESS", "FAILED"] as const;
export type EmailState = (typeof emailStates)[number];

export class EmailMessage {
  @IsString()
  subject: string;
  @IsString()
  text: string;
  @IsString()
  html: string;
}

export class EmailAttachment {
  @IsString()
  content: string;
  @IsString()
  filename: string;
}

export class EmailDeliveryInfo {
  @IsOptional()
  @IsString()
  messageId?: string;
  @IsArray()
  @IsString({ each: true })
  accepted: string[];
  @IsArray()
  @IsString({ each: true })
  rejected: string[];
  @IsArray()
  @IsString({ each: true })
  pending: string[];
  @IsOptional()
  response?: string;
}

export class EmailDelivery {
  @IsInt()
  attempts: number;
  @IsEnum(emailStates)
  state: EmailState;
  @Transform(transformMoment)
  @IsMoment()
  startTime: Moment;
  @Transform(transformMoment)
  @IsMoment()
  endTime: Moment;
  @IsOptional()
  @IsString()
  error?: string;
  @IsOptional()
  @Type(() => EmailDeliveryInfo)
  @ValidateNested()
  info?: EmailDeliveryInfo;
}

export class EmailSchedule {
  @IsOptional()
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
  @IsOptional()
  @IsString()
  taskId: string;
}

export class Email {
  @IsUUID()
  emailId: string;
  @IsString()
  from: string;
  @IsArray()
  @IsString({ each: true })
  to: string[];
  @IsArray()
  @IsString({ each: true })
  cc: string[];
  @IsArray()
  @IsString({ each: true })
  bcc: string[];
  @ValidateNested()
  @Type(() => EmailMessage)
  message: EmailMessage;
  @IsOptional()
  @IsArray()
  @Type(() => EmailAttachment)
  @ValidateNested()
  attachments: EmailAttachment[];
  @IsOptional()
  @IsYup(yupRecord(yup.string().required(), yup.string()))
  headers?: Record<string, string>;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
  @IsOptional()
  @ValidateNested()
  @Type(() => EmailDelivery)
  delivery?: EmailDelivery;
  @IsOptional()
  @Type(() => EmailSchedule)
  @ValidateNested()
  schedule: EmailSchedule;
}
