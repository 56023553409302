import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { User } from "react-feather";

import UserWrapper from "./components/UserWrapper";
import { Module } from "../../types";

const UserPage = lazy(() => import("./pages/UserPage"));
const UserQuizPage = lazy(() => import("./pages/UserQuizPage"));
const UserQuizDetailPage = lazy(() => import("./pages/UserQuizDetailPage"));
const UserQuizLastMistakenAnswersPage = lazy(() => import("./pages/UserQuizLastMistakenAnswersPage"));
const UserLessonsPage = lazy(() => import("./pages/UserLessonsPage"));
const UserCourseRegistrationsPage = lazy(() => import("./pages/UserCourseRegistrationsPage"));
const UserSettingsPage = lazy(() => import("./pages/UserSettingsPage"));

export default {
  route: {
    path: "/user",
    element: <UserWrapper />,
    children: [
      {
        index: true,
        path: "/user",
        element: <UserPage />,
      },
      {
        path: "/user/quiz",
        element: <UserQuizPage />,
      },
      {
        path: "/user/quiz/:quizSessionId",
        element: <UserQuizDetailPage />,
      },
      {
        path: "/user/quiz/last-mistaken-answers",
        element: <UserQuizLastMistakenAnswersPage />,
      },
      {
        path: "/user/lessons",
        element: <UserLessonsPage />,
      },
      {
        path: "/user/course-registrations",
        element: <UserCourseRegistrationsPage />,
      },
      {
        path: "/user/settings",
        element: <UserSettingsPage />,
      },
    ]
  },
  allowed: () => true,
  name: <FormattedMessage id="module.user.name" defaultMessage="User" />,
  icon: <User />,
} satisfies Module;
