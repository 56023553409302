import moment, { Duration, Moment } from "moment";
import { interpolateRgb } from "d3-interpolate";
import { Series } from "data-forge";

import {
  StatsAggregator,
  quizQuestionModel,
  quizSessionModel,
  ModelQueryData,
  ModelQueryMetric,
  ModelQueryMetricType,
  StatsModel,
  Category,
} from "@namedicinu/internal-types";

import { QuizReleaseConfig, QuestionSetConfig } from "../types";
import { formatDuration } from "./utils";

export const getDatesInRange = (range: { from: Moment; to: Moment }): Moment[] => {
  const dates = [];
  const date = moment(range.from);
  while (date.isBefore(range.to)) {
    dates.push(moment(date));
    date.add(1, "day");
  }
  return dates;
};

export function fillDateSeries<
  M extends StatsModel,
  MQ extends ModelQueryMetric<M>,
  R extends ModelQueryMetricType<M, MQ>,
>(
  dateRange: { from: Moment; to: Moment },
  data: ModelQueryData<M, "date", MQ>,
  metric: MQ,
  defaultValue: R,
): ApexAxisChartSeries[number]["data"] {
  return getDatesInRange(dateRange).map((date) => {
    const entry = data.find((e) => e.date.valueOf() === date.valueOf());
    return {
      x: date.valueOf(),
      y: (entry ? entry[metric] : defaultValue) as R,
    };
  });
}

export function fillDateSeriesAdjustedColor<
  M extends StatsModel,
  MQ extends ModelQueryMetric<M>,
  MQC extends ModelQueryMetric<M>,
  R extends ModelQueryMetricType<M, MQ>,
>(
  dateRange: { from: Moment; to: Moment },
  data: ModelQueryData<M, "date", MQ | MQC>,
  metric: MQ,
  colorMetric: MQC,
  defaultValue: R,
): ApexAxisChartSeries[number]["data"] {
  return getDatesInRange(dateRange).map((date) => {
    const entry = data.find((e) => e.date.valueOf() === date.valueOf());
    return {
      x: date.valueOf(),
      y: (entry ? entry[metric] : defaultValue) as number,
      fillColor: adjustedScoreColor(entry ? entry[colorMetric] : defaultValue),
    };
  });
}

export function fillDateSeriesCompletness<M extends StatsModel, MQ extends ModelQueryMetric<M>>(
  dateRange: { from: Moment; to: Moment },
  data: ModelQueryData<M, "date", MQ>,
  metric: MQ,
  total: number,
): ApexAxisChartSeries[number]["data"] {
  let cumsum = 0;
  return getDatesInRange(dateRange).map((date) => {
    const entry = data.find((e) => e.date.valueOf() === date.valueOf());
    cumsum += (entry ? entry[metric] : 0) / total;
    return {
      x: date.valueOf(),
      y: cumsum,
    };
  });
}

const OFFSET_BUCKET_SIZE = 120;
export function fillOffsetBucketSeries<
  M extends StatsModel,
  MQ extends ModelQueryMetric<M>,
  R extends ModelQueryMetricType<M, MQ>,
>(duration: Duration, data: ModelQueryData<M, "offsetBucket", MQ>, metric: MQ, defaultValue: R): { x: string; y: R }[] {
  const buckets = Math.ceil(duration.asSeconds() / OFFSET_BUCKET_SIZE);
  const maxMetric = data.reduce((acc, e) => Math.max(acc, e[metric]), 0);
  return new Array(buckets).fill(null).map((_, i) => {
    const entry = data.find((e) => e.offsetBucket === i);
    return {
      x: formatDuration(moment.duration(i * OFFSET_BUCKET_SIZE, "seconds")),
      y: (entry ? entry[metric] / maxMetric : defaultValue) as R,
    };
  });
}

export function fillWeekHourHeatmapSeries<
  M extends StatsModel,
  MQ extends ModelQueryMetric<M>,
  R extends ModelQueryMetricType<M, MQ>,
>(data: ModelQueryData<M, "weekday" | "hour", MQ>, metric: MQ, defaultValue: R): { x: string; y: R }[][] {
  const weekdayValues = new Array(7).fill(null).map((_, i) => i + 1);
  const hourValues = new Array(24).fill(null).map((_, i) => i);

  const result = weekdayValues.map(() =>
    hourValues.map((hour) => {
      return { x: `${hour}`, y: defaultValue as R };
    }),
  );

  for (const entry of data) {
    result[(entry.weekday as number) - 1]![entry.hour as number]!.y = entry[metric] as R;
  }
  return result;
}

export function fillFrequencyCounts(data: number[]): { x: string; y: number }[] {
  const lower = Math.floor(Math.min(...data));
  const upper = Math.ceil(Math.max(...data));
  const groupCounts = new Array(upper - lower + 1).fill(0);
  for (const score of data) {
    groupCounts[Math.floor(score - lower)]!++;
  }
  return groupCounts.map((count, i) => ({ x: `${i + lower}`, y: count }));
}

export const adjustedScoreColor = (v: number, bg: boolean = true): string => {
  if (v >= 0 && v <= 1) {
    return bg ? interpolateRgb("#f9f7ae", "#22964f")(v) : interpolateRgb("#ee9537", "#22964f")(v);
  } else {
    return bg ? "white" : "unset";
  }
};

export const aggregateHigherLevelStats = (
  data: ModelQueryData<
    typeof quizQuestionModel,
    "categoryId" | "topicId" | "area",
    "avg-score-adj" | "total" | "total-time"
  >,
  content: Category[],
  sort: "numeric" | "score-asc" | "score-desc",
  quizReleases: { [categoryId: string]: QuizReleaseConfig },
): Array<{
  categoryId: string;
  categoryAvgScore: number | undefined;
  categoryTotal: number | undefined;
  categoryTime: Duration | undefined;
  topics: Array<{
    topicId: string;
    topicAvgScore: number | undefined;
    topicTotal: number | undefined;
    topicTime: Duration | undefined;
    areas: Array<{
      area: string;
      areaAvgScore: number | undefined;
      areaTotal: number | undefined;
      areaTime: Duration | undefined;
    }>;
  }>;
}> => {
  const aggregator = new StatsAggregator(quizSessionModel, "", "");

  const categoriesLines = content.map((category) => {
    const categoryData = aggregator.aggregateSecondary(data, ["categoryId"], { categoryId: category.categoryId }, [
      "avg-score-adj",
      "total",
      "total-time",
    ]);

    const topicsLines = category.topics.map((topic) => {
      const topicData = aggregator.aggregateSecondary(
        data,
        ["categoryId", "topicId"],
        { categoryId: category.categoryId, topicId: topic.topicId },
        ["avg-score-adj", "total", "total-time"],
      );
      const areas: QuestionSetConfig["areas"] = [];
      for (const t of quizReleases[category.categoryId]?.topics ?? []) {
        if (t.topicId === topic.topicId) {
          for (const questionSetConfig of t.questionSetConfigs) {
            areas.push(...questionSetConfig.areas);
          }
        }
      }

      const areasLines = areas.map((area) => {
        const areaData = aggregator.aggregateSecondary(
          data,
          ["categoryId", "topicId", "area"],
          { categoryId: category.categoryId, topicId: topic.topicId, area: area.title },
          ["avg-score-adj", "total", "total-time"],
        );

        return {
          area: area.title,
          areaAvgScore: areaData[0] ? areaData[0]["avg-score-adj"] : undefined,
          areaTotal: areaData[0] ? areaData[0]["total"] : undefined,
          areaTime: areaData[0] ? moment.duration(areaData[0]["total-time"], "s") : undefined,
        };
      });

      if (sort === "score-asc") {
        areasLines.sort((a, b) => (a.areaAvgScore ?? 0) - (b.areaAvgScore ?? 0));
      } else if (sort === "score-desc") {
        areasLines.sort((a, b) => (b.areaAvgScore ?? 0) - (a.areaAvgScore ?? 0));
      }

      return {
        topicId: topic.topicId,
        topicAvgScore: topicData[0] ? topicData[0]["avg-score-adj"] : undefined,
        topicTotal: topicData[0] ? topicData[0]["total"] : undefined,
        topicTime: topicData[0] ? moment.duration(topicData[0]["total-time"], "s") : undefined,
        areas: areasLines,
      };
    });

    if (sort === "score-asc") {
      topicsLines.sort((a, b) => (a.topicAvgScore ?? 0) - (b.topicAvgScore ?? 0));
    } else if (sort === "score-desc") {
      topicsLines.sort((a, b) => (b.topicAvgScore ?? 0) - (a.topicAvgScore ?? 0));
    }

    return {
      categoryId: category.categoryId,
      categoryAvgScore: categoryData[0] ? categoryData[0]["avg-score-adj"] : undefined,
      categoryTotal: categoryData[0] ? categoryData[0]["total"] : undefined,
      categoryTime: categoryData[0] ? moment.duration(categoryData[0]["total-time"], "s") : undefined,
      topics: topicsLines,
    };
  });

  if (sort === "score-asc") {
    categoriesLines.sort((a, b) => (a.categoryAvgScore ?? 0) - (b.categoryAvgScore ?? 0));
  } else if (sort === "score-desc") {
    categoriesLines.sort((a, b) => (b.categoryAvgScore ?? 0) - (a.categoryAvgScore ?? 0));
  }

  return categoriesLines;
};

export const convertValues = <T, R, K extends string = string>(
  obj: Record<K, T>,
  convert: (v: T) => R,
): Record<K, R> => {
  const res = {} as Record<K, R>;
  for (const key in obj) {
    res[key] = convert(obj[key]);
  }
  return res;
};

type AggregateType = "avg" | "median" | "sum" | "min" | "max";
// assumes vals are of the same type
export const aggregate = <T extends number | Moment | Duration | null | undefined, A extends AggregateType>(
  vals: T[],
  aggs: A[],
): Record<A, T | undefined> => {
  const valid = vals.filter((v) => v !== undefined && v !== null);

  if (valid.length == 0) {
    return Object.fromEntries(aggs.map((agg) => [agg, undefined])) as Record<A, T | undefined>;
  }

  const types = new Set(
    valid.map((v) => (moment.isMoment(v) ? "moment" : moment.isDuration(v) ? "duration" : typeof v)),
  );
  if (types.size != 1) {
    throw new Error("All values must be of the same type");
  }
  const type = types.values().next().value;

  const res = {} as Record<A, number | undefined>;
  const series = new Series<number | undefined>(
    valid.map((v) =>
      type == "moment" ? (v as Moment).valueOf() : type == "duration" ? (v as Duration).asSeconds() : v,
    ),
  );

  for (const agg of aggs) {
    switch (agg) {
      case "avg":
        res[agg] = series.average();
        break;
      case "median":
        res[agg] = series.median();
        break;
      case "sum":
        res[agg] = series.sum();
        break;
      case "min":
        res[agg] = series.min();
        break;
      case "max":
        res[agg] = series.max();
        break;
    }
  }

  if (type == "moment") {
    return convertValues(res, (v) => (v !== undefined ? moment(v) : undefined)) as Record<A, T | undefined>;
  } else if (type == "duration") {
    return convertValues(res, (v) => (v !== undefined ? moment.duration(v, "seconds") : undefined)) as Record<
      A,
      T | undefined
    >;
  } else {
    return res as Record<A, T | undefined>;
  }
};

export const aggregateAvg = <T extends number | Duration | null | undefined>(vals: T[]): T | undefined => {
  const res = aggregate(vals, ["avg"]);
  return res["avg"] as T | undefined;
};

export const aggregateSum = <T extends number | Duration | null | undefined>(vals: T[]): T | undefined => {
  const res = aggregate(vals, ["sum"]);
  return res["sum"] as T | undefined;
};
