import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

// const showErrorOverlay = (err: unknown) => {
//   const ErrorOverlay = customElements.get("vite-error-overlay");
//   if (!ErrorOverlay) {
//     return;
//   }
//   const overlay = new ErrorOverlay(err);
//   document.body.appendChild(overlay);
// };

export const handleError = (error: unknown) => {
  console.error(error);
  // if (import.meta.env.DEV) {
  //   showErrorOverlay({ message: errorMessage(error) });
  // } else {
  toast.error(errorMessage(error), { autoClose: false });
  Sentry.captureException(error);
  // }
};

export const errorMessage = (error: unknown): string => {
  if ((error as any)?.response?.data) {
    error = (error as any).response.data;
  }
  if (error instanceof Error) {
    return error.message;
  } else if (error && typeof error == "object" && typeof (error as any).message == "string") {
    return (error as any).message;
  } else if (typeof error == "string") {
    return error;
  } else {
    return "Unknown error occurred";
  }
};

// catch unhanded errors
window.addEventListener("error", (event: ErrorEvent) => handleError(event.error));
window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => handleError(event.reason));
