import { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

import useLocale from "../hooks/useLocale";

const Title: FunctionComponent<{
  children: string;
}> = ({
  children: title,
}) => {
  const locale = useLocale();

  return (
    <Helmet>
      <title>
        {title} | {locale == "en" ? "Premedical course" : "Na medicínu"}
      </title>
    </Helmet>
  );
}

export default Title;