import axios from "axios";

const extractMainHash = (html: string) => {
  const m = html.match(/src="\/assets\/index-(.*?)\.js"/);
  return m ? m[1] : undefined;
};

export function enableReloadChecker() {
  const originalHash = extractMainHash(document.head.innerHTML);
  let lastActivity = Date.now();

  setInterval(() => {
    if (lastActivity < Date.now() - 350000) {
      // at least 5 minutes ago
      axios.get<Document>("/index.html", { responseType: "document" }).then((res) => {
        const newHash = extractMainHash(res.data.head.innerHTML);

        if (originalHash && newHash && newHash !== originalHash) {
          window.location.reload();
        }
      });
    }
  }, 10800000); // 3 hours

  const resetActivity = () => {
    lastActivity = Date.now();
  };

  document.addEventListener("mousedown", resetActivity);
  document.addEventListener("keydown", resetActivity);
}
