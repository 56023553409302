import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

const VideoWrapper: FunctionComponent = () => {
  return (
    <>
      <Outlet />
    </>
  );
}

export default VideoWrapper;