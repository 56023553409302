import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "reactstrap";

const LoadingPlaceholder: FunctionComponent = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h5 className="card-title d-flex align-items-center gap-3">
                <Spinner color="dark" />
                <span>
                  <FormattedMessage id="common.loading" defaultMessage="Loading..." />
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPlaceholder;