import { IsBoolean, IsString } from "class-validator";
import { Transform } from "class-transformer";
import type { Moment } from "moment";

import { transformMoment } from "../helpers/transforms";
import { IsMoment } from "../helpers/decorators";

export class UserReferenceClaim {
  @IsString()
  user: string;
  @IsString()
  reference: string;
  @IsBoolean()
  claimed: boolean;
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
}
