import { FunctionComponent } from "react";
import { AlertTriangle, Bell, MessageCircle, ThumbsUp } from "react-feather";
import { FormattedMessage, FormattedRelativeTime } from "react-intl";
import { Button, Spinner } from "reactstrap";
import moment from "moment";

import useApp from "../hooks/useApp";

const Notifications: FunctionComponent = () => {
  const { notifications, markNotificationsAcknowledged } = useApp();
  
  return (
    <li className="onhover-dropdown">
      {notifications?.length ? (
        <div className="notification-new" />
      ) : null}
      <Bell color={notifications?.length ? "var(--bs-white)" : "var(--bs-primary)"} />
      <ul className="onhover-show-div notification-dropdown">
        <li className="card-g-primary">
          <h5 className="f-w-700">
            <FormattedMessage id="common.header.notifications" defaultMessage="Notifications" />
          </h5>  
        </li>
        {notifications !== undefined ? (
          notifications.length > 0 ? (
            <>
              {notifications.map(({ message, type, createdAt }) => (
                <li>
                  <div className="media">
                    {type == "info" ? (
                      <div className="notification-icons bg-success me-3">
                        <ThumbsUp className="mt-0" />
                      </div>
                    ) : type == "message" ? (
                      <div className="notification-icons bg-info me-3">
                        <MessageCircle className="mt-0" />
                      </div>
                    ) : type == "warning" ? (
                      <div className="notification-icons bg-warning me-3">
                        <AlertTriangle className="mt-0" />
                      </div>
                    )  : (
                      <div className="notification-icons bg-primary me-3">
                        <Bell className="mt-0" />
                      </div>
                    )}
                    <div className="media-body">
                      <div className="h6">
                        {message}
                      </div>
                      <p className="mb-0">
                        <FormattedRelativeTime value={createdAt.diff(moment(), "minutes")} updateIntervalInSeconds={60} unit="minute" />
                      </p>
                    </div>
                  </div>
                </li>
              ))}
              <li className="bg-light txt-dark text-end">
                <Button onClick={markNotificationsAcknowledged} color="link" className="txt-dark mb-0">
                  <FormattedMessage id="common.header.markAllAcknowledged" defaultMessage="Mark all as acknowledged" />
                </Button>
              </li>
            </>
          ) : (
            <li className="bg-light txt-dark">
              <div className="d-flex justify-content-center">
                <FormattedMessage id="common.header.noNotifications" defaultMessage="No notifications" />
              </div>
            </li>
          )
        ) : (
          <li>
            <div className="d-flex justify-content-center">
              <Spinner color="primary" />
            </div>
          </li>
        )}
      </ul>
    </li>
  )
}

export default Notifications;