import { IsInt, IsOptional, IsString } from "class-validator";
import { WebEntry } from "./webEntry";
import { IsReference } from "../helpers/decorators";

export class FacultyStudents extends WebEntry {
  public get type() {
    return "facultyStudents" as const;
  }

  @IsString()
  identifier: string;
  @IsReference("faculty")
  faculty: string;
  @IsInt()
  year: number;
  @IsInt()
  generalSigned: number;
  @IsInt()
  generalAccepted: number;
  @IsOptional()
  @IsInt()
  dentistrySigned: number | null;
  @IsOptional()
  @IsInt()
  dentistryAccepted: number | null;
}
