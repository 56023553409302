import { FunctionComponent } from "react";
import { FormattedMessage, FormattedDate } from "react-intl";

import useApp from "../hooks/useApp";
import { Input } from "reactstrap";
import { userViewId } from "../helpers/utils";

const UserViewSelector: FunctionComponent = () => {
  const { userViews, userView, pickUserView } = useApp();

  return (
    <Input
      type="select"
      value={userViewId(userView)}
      onChange={(e) => pickUserView(userViews.find(uv => userViewId(uv) == e.target.value)!)}
      style={{maxWidth: "160px"}}
    >
      {userViews.map((view) => (
        <option key={userViewId(view)} value={userViewId(view)}>
          {view.role ? (
            <FormattedMessage id={`module.user.role.${view.role}`} />
          ) : view.courseRegistration ? (
            <FormattedDate value={view.courseRegistration.timestamp.valueOf()} year="numeric" month="numeric" day="numeric" />
          ) : null}
        </option>
      ))}
    </Input>
  )
}

export default UserViewSelector;