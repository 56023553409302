import { IsIP, IsOptional, IsString, IsUUID } from "class-validator";

export class Client {
  @IsUUID()
  clientId: string;
  @IsOptional()
  @IsIP()
  ip: string | null;
  @IsOptional()
  @IsString()
  browser: string | null;
  @IsOptional()
  @IsString()
  browserVersion: string | null;
  @IsOptional()
  @IsString()
  os: string | null;
  @IsOptional()
  @IsString()
  osVersion: string | null;
  @IsOptional()
  @IsString()
  device: string | null;
  @IsOptional()
  @IsString()
  deviceType: string | null;
  @IsOptional()
  @IsString()
  deviceVendor: string | null;
}
