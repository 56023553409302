import { IsOptional, IsUrl } from "class-validator";
import { WebEntry } from "./webEntry";
import { IsReference, IsLocalizedInfoPanel, IsLocalizedString, IsLocalizedMarkdown } from "../helpers/decorators";
import type { JsonArray } from "@namedicinu/internal-types";
import { Transform } from "class-transformer";
import { Localized, transformLocalized } from "@namedicinu/internal-types";

export class FacultyField extends WebEntry {
  public get type() {
    return "facultyField" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
  @IsReference("faculty")
  faculty: string;
  @IsReference("studyField")
  studyField: string;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  description: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedInfoPanel()
  descriptionPanel: Localized<JsonArray>;
  @IsOptional()
  @IsUrl()
  website: string | null;
}
