import { FunctionComponent, ReactNode } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Home } from "react-feather";

export type BreadcrumbsProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  parent: ReactNode;
};

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({title, subtitle, parent}) => {
  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="6" className="main-header">
            <h2>
              {title}
            </h2>
            {subtitle ? (
              <h6 className="mb-0">{subtitle}</h6>
            ) : (
              <h6 className="mb-0">{parent}</h6>
            )}
          </Col>
          <Col lg="6" className="breadcrumb-right">
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to={`/`}>
                  <Home size={18} />
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>{parent}</BreadcrumbItem>
              <BreadcrumbItem active>{title}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Breadcrumbs;
