import { IsArray, IsString } from "class-validator";
import { WebEntry } from "./webEntry";
import { IsReference } from "../helpers/decorators";

export class ProductVariation extends WebEntry {
  public get type() {
    return "productVariation" as const;
  }

  @IsString()
  identifier: string;
  @IsReference("product")
  product: string;
  @IsReference("faculty")
  faculty: string;
  @IsArray()
  @IsString({ each: true })
  variations: Array<string>;
}
