import { LocaleType } from "@namedicinu/internal-types";

import useApp from "./useApp";

const siteDefault: LocaleType =
  window.location.host == "login.namedicinu.sk"
    ? "sk"
    : window.location.host == "login.namedicinu.cz"
      ? "cs"
      : window.location.host == "login.premedicalcourse.com"
        ? "en"
        : "sk";

const useLocale = (): LocaleType => {
  const { user } = useApp();
  return user ? user.getLocale() : siteDefault;
};

export default useLocale;
