import { Buffer } from "buffer";
import axios from "axios";
import moment from "moment";
import pako from "pako";

import {
  User,
  UserAffinity,
  guidFrom,
  instanceValidate,
  quizAnswerModel,
  quizQuestionModel,
  quizSessionModel,
} from "@namedicinu/internal-types";

import { QuizReleaseConfig } from "../types";
import ApiClient from "./apiClient";
import LocalDatabase from "./localDatabase";
import { QuizLogEntry, QuizLogEntryJournal, QuizLogSelection, QuizSessionJournal } from "./types";
import StatsClient from "./statsClient";
import StorageClient from "./storageClient";
import UserQuizAffinity from "../modules/quiz/helpers/UserQuizAffinity";
import { aggregateAvg, aggregateSum } from "../helpers/analytics";

const CURRENT_LOG_VERSION = 2;

export default class QuizClient {
  private loadedReleases = new Map<string, QuizReleaseConfig>();

  constructor(
    private apiClient: ApiClient,
    private storageClient: StorageClient,
    private statsClient: StatsClient,
    private localDatabase: LocalDatabase,
  ) {}

  async getQuizRelease(categoryId: string): Promise<QuizReleaseConfig> {
    const loadedRelease = this.loadedReleases.get(categoryId);
    if (loadedRelease) {
      return loadedRelease;
    }

    const cachedRelease = await this.localDatabase.getQuizReleaseOptional(categoryId);
    try {
      const currentRelease = await this.apiClient.getQuizRelease(categoryId);
      if (cachedRelease) {
        if (cachedRelease.quizReleaseId == currentRelease.quizReleaseId) {
          return cachedRelease;
        }
      }

      const res = await axios.get<ArrayBuffer>(
        this.storageClient.getPublicUrl(`quiz/${currentRelease.quizReleaseId}.json.gz.aes`),
        {
          responseType: "arraybuffer",
        },
      );
      const encrypted = res.data;

      const [ivStr, keyStr] = Object.entries(currentRelease.playbackKeys)[0]!;
      const iv = Buffer.from(ivStr, "hex");
      const key = await crypto.subtle.importKey("raw", Buffer.from(keyStr, "hex"), "AES-CBC", false, ["decrypt"]);

      const decrypted = await crypto.subtle.decrypt({ name: "AES-CBC", iv }, key, encrypted);
      const decompressed = Buffer.from(pako.inflate(decrypted));
      const release = JSON.parse(decompressed.toString("utf-8")) as QuizReleaseConfig;

      await this.localDatabase.storeQuizRelease(release);

      this.loadedReleases.set(categoryId, release);
      return release;
    } catch (e) {
      console.error(e);
      // fallback to local database
      if (!cachedRelease) {
        throw new Error("Unable to load quiz release");
      }
      console.warn("Using offline mode");

      this.loadedReleases.set(categoryId, cachedRelease);
      return cachedRelease;
    }
  }

  async getQuizReleases(categoryIds: string[]): Promise<{ [categoryId: string]: QuizReleaseConfig }> {
    const releases: { [categoryId: string]: QuizReleaseConfig } = {};
    for (const categoryId of categoryIds) {
      releases[categoryId] = await this.getQuizRelease(categoryId);
    }
    return releases;
  }

  async getUserQuizAffinity(user: User): Promise<UserQuizAffinity> {
    const cachedUserAffinity = await this.localDatabase.getUserAffinity(user.userId, "quiz");
    if (cachedUserAffinity && moment.unix(cachedUserAffinity.lastRefresh).add(35, "hours").isAfter(moment())) {
      const userAffinity = await instanceValidate(UserAffinity, { affinity: cachedUserAffinity.affinity });
      return new UserQuizAffinity(userAffinity, moment.unix(cachedUserAffinity.lastRefresh));
    } else {
      const userAffinity = await this.apiClient.getUserQuizAffinity();

      await this.localDatabase.storeUserAffinity({
        userId: user.userId,
        type: "quiz",
        affinity: userAffinity.affinity,
        lastRefresh: moment().unix(),
        lastLocalChange: moment().unix(),
      });

      return new UserQuizAffinity(userAffinity, moment());
    }
  }

  async storeUserQuizAffinity(user: User, userQuizAffinity: UserQuizAffinity): Promise<void> {
    await this.localDatabase.storeUserAffinity({
      userId: user.userId,
      type: "quiz",
      lastRefresh: userQuizAffinity.lastRefresh.unix(),
      lastLocalChange: moment().unix(),
      affinity: userQuizAffinity.affinity,
    });
  }

  async startSession(
    user: User,
    localSessionId: string,
    selection: QuizLogSelection,
    linkId: string | undefined,
  ): Promise<void> {
    await this.localDatabase.storeQuizSession({
      userId: user.userId,
      logVersion: CURRENT_LOG_VERSION,
      localSessionId,
      timestamp: moment().toISOString(),
      linkId: linkId || null,
      selection,
      open: true,
    });
  }

  closeSession(user: User, localSessionId: string): Promise<QuizSessionJournal & { open: false }>;
  closeSession(
    user: User,
    session: QuizSessionJournal & { open: true },
    entries: QuizLogEntryJournal[],
  ): Promise<QuizSessionJournal & { open: false }>;
  async closeSession(
    user: User,
    sessionOrId: (QuizSessionJournal & { open: true }) | string,
    entries?: QuizLogEntryJournal[],
  ) {
    // unify argujments
    let openSession: QuizSessionJournal & { open: true };
    if (typeof sessionOrId == "string") {
      const session = await this.localDatabase.getQuizSession(user.userId, sessionOrId);
      if (!session.open) {
        return;
      }
      openSession = session;
      entries = await this.localDatabase.getQuizLogEntries(sessionOrId);
    } else {
      openSession = sessionOrId;
    }
    if (!entries) throw new Error("Entries missing");

    const score = aggregateSum(entries.map((e) => e.entry.score)) || 0;
    const scoreAdj = aggregateAvg(entries.map((e) => e.entry.scoreAdj)) || 0;
    const lastTimestamp = entries.reduce((acc, e) => Math.max(acc, moment(e.entry.time).valueOf()), 0);
    const time = moment.duration(moment(lastTimestamp).diff(moment(openSession.timestamp))).toISOString();

    const session = {
      ...openSession,
      open: false as const,
      score,
      scoreAdj,
      time,
    };
    await this.localDatabase.storeQuizSession(session);

    await this.statsClient.storeLocalStats(user, quizSessionModel, [
      {
        email: user.userId,
        country: user.country,
        studyGroupIds: "",
        clientId: "local-placeholder",
        browser: "local",
        os: "local",
        modeId: session.selection.modeId,
        selectionId: `local-${guidFrom(session.selection)}`,
        showAnswers: session.selection.showAnswers,
        shuffleAnswers: session.selection.shuffleAnswers,
        allQuestionsAtOnce: session.selection.allQuestionsAtOnce,
        allowQuestionReminders: session.selection.allowQuestionReminders,
        selectQuestionsRange: `${session.selection.selectQuestionsRange.from}-${session.selection.selectQuestionsRange.to}`,
        selectQuestionAreas: session.selection.selectQuestionAreas ? session.selection.selectQuestionAreas.length : 0,
        questionsPerQuiz: session.selection.questionsPerQuiz,
        questionsPreddefined: session.selection.questionsPreddefined,
        answersToChooseFrom: session.selection.answersToChooseFrom,
        timer: session.selection.timer || 0,
        linkId: "",
        categoryId: session.selection.categoryId,
        date: moment().utc().startOf("day"),
        week: moment().utc().startOf("week"),
        weekday: moment().utc().isoWeekday(),
        hour: moment().utc().hour(),
        timestamp: moment().utc(),
        score,
        scoreAdj,
        time: moment.duration(time).asSeconds(),
        questions: entries.length,
      },
    ]);

    return session;
  }

  async storeLogEntry(user: User, localSessionId: string, entry: QuizLogEntry): Promise<void> {
    await this.localDatabase.storeQuizLogEntry({
      localSessionId,
      entry,
    });

    await this.statsClient.storeLocalStats(user, quizQuestionModel, [
      {
        email: user.userId,
        categoryId: entry.categoryId,
        topicId: entry.topicId,
        area: entry.area,
        quid: entry.quid,
        date: moment(entry.timestamp).utc().startOf("day"),
        week: moment().utc().startOf("week"),
        timestamp: moment(entry.timestamp).utc(),
        score: entry.score,
        scoreAdj: entry.scoreAdj,
        time: moment.duration(entry.time).asSeconds(),
      },
    ]);
    await this.statsClient.storeLocalStats(
      user,
      quizAnswerModel,
      entry.options.map((option) => ({
        categoryId: entry.categoryId,
        topicId: entry.topicId,
        quid: entry.quid,
        option: option.option,
        timestamp: moment(entry.timestamp).utc(),
        correct: option.correct,
        toggles: option.toggles,
      })),
    );
  }

  async storeLogEntries(
    user: User,
    localSessionId: string,
    selection: QuizLogSelection,
    entries: Array<QuizLogEntry>,
  ): Promise<void> {
    await this.localDatabase.storeQuizLogEntries(entries.map((entry) => ({ localSessionId, entry })));

    await this.statsClient.storeLocalStats(
      user,
      quizQuestionModel,
      entries.map((entry) => ({
        email: user.userId,
        clientId: "local-placeholder",
        modeId: selection.modeId,
        selectionId: `local-${guidFrom(selection)}`,
        linkId: "",
        categoryId: entry.categoryId,
        topicId: entry.topicId,
        area: entry.area,
        quid: entry.quid,
        date: moment(entry.timestamp).utc().startOf("day"),
        week: moment().utc().startOf("week"),
        timestamp: moment(entry.timestamp).utc(),
        score: entry.score,
        scoreAdj: entry.scoreAdj,
        time: moment.duration(entry.time).asSeconds(),
      })),
    );
    await this.statsClient.storeLocalStats(
      user,
      quizAnswerModel,
      entries.flatMap((entry) =>
        entry.options.map((option) => ({
          categoryId: entry.categoryId,
          topicId: entry.topicId,
          quid: entry.quid,
          option: option.option,
          timestamp: moment(entry.timestamp).utc(),
          correct: option.correct,
          toggles: option.toggles,
        })),
      ),
    );
  }

  async offloadLogs(user: User, currentLocalSession: string | undefined): Promise<void> {
    const sessions = await this.localDatabase.getQuizSessions(user.userId);
    for (let openSession of sessions) {
      if (openSession.localSessionId === currentLocalSession) {
        continue;
      }

      try {
        const entries = await this.localDatabase.getQuizLogEntries(openSession.localSessionId);

        let session: QuizSessionJournal & { open: false };
        if (openSession.open) {
          // wasn't closed yet
          session = await this.closeSession(user, openSession, entries);
        } else {
          session = openSession;
        }

        const quizLogRequest = {
          logVersion: session.logVersion || 0,
          quizSessionId: session.localSessionId,
          selection: session.selection,
          score: session.score,
          scoreAdj: session.scoreAdj,
          time: session.time,
          timestamp: session.timestamp,
          linkId: session.linkId,
          entries: entries.map((entry) => entry.entry),
        };

        await this.apiClient.submitQuizLog(quizLogRequest);

        await this.localDatabase.clearQuizLog(session.localSessionId);
      } catch (e) {
        console.error("Failed to offload logs", e);
      }
    }
  }
}
