import { ClassConstructor, plainToInstance } from "class-transformer";
import { validate } from "class-validator";

import { WebEntryType } from "../types";

import { Blog } from "../models/blog";
import { Faculty } from "../models/faculty";
import { FacultyField } from "../models/facultyField";
import { FacultyStudents } from "../models/facultyStudents";
import { FAQ } from "../models/faq";
import { Lecturer } from "../models/lecturer";
import { PaymentFrequency } from "../models/paymentFrequency";
import { Product } from "../models/product";
import { ProductVariation } from "../models/productVariation";
import { QuizQuestion } from "../models/quizQuestion";
import { SiteInformation } from "../models/siteInformation";
import { StudyField } from "../models/studyField";
import { Term } from "../models/term";
import { Testimonial } from "../models/testimonial";
import { WebEntry } from "../models/webEntry";

const ctors = {
  blog: Blog,
  faculty: Faculty,
  facultyField: FacultyField,
  facultyStudents: FacultyStudents,
  faq: FAQ,
  lecturer: Lecturer,
  paymentFrequency: PaymentFrequency,
  product: Product,
  productVariation: ProductVariation,
  quizQuestion: QuizQuestion,
  siteInformation: SiteInformation,
  studyField: StudyField,
  term: Term,
  testimonial: Testimonial,
} satisfies { [key in WebEntryType]: ClassConstructor<WebEntry> };

export const getWebEntryConstructor = <T extends WebEntry>(type: WebEntryType): ClassConstructor<T> => {
  return ctors[type] as unknown as ClassConstructor<T>;
};

export async function instanceValidateWebEntry<T extends WebEntry>(
  type: WebEntryType,
  data: any,
  strict: boolean = true,
): Promise<T> {
  const ctor = getWebEntryConstructor(type);
  const instance = plainToInstance(ctor, data);
  const errors = await validate(instance, {
    whitelist: true,
    forbidNonWhitelisted: strict,
  });
  if (errors.length > 0) {
    throw new Error(`${ctor.name} validation failed (${errors.length}): ${errors[0]!.toString()}`, { cause: errors });
  }
  return instance as unknown as T;
}

export async function instancesValidateWebEntry<T extends WebEntry>(type: WebEntryType, data: any[]): Promise<T[]> {
  const instances: T[] = [];
  for (const datum of data) {
    instances.push(await instanceValidateWebEntry(type, datum));
  }
  return instances;
}
