import moment, { Duration, Moment } from "moment";
import slugify from "slugify";

import { Trove } from "@namedicinu/trove-search";

import {
  Category,
  CourseRegistration,
  Localized,
  StudyGroup,
  StudyMaterial,
  TractType,
  User,
} from "@namedicinu/internal-types";

import { DateRange, UserContentTree, UserView } from "../types";

export const NotFound = Symbol("NotFound");
export type NotFound = typeof NotFound;

export const isNotFound = (value: any): value is NotFound => value === NotFound;
export const isFound = <T>(value: T | NotFound | undefined): value is T => value !== undefined && value !== NotFound;

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const setEquals = (a: Iterable<any>, b: Iterable<any>) => {
  const setA = new Set(a);
  const setB = new Set(b);
  if (setA.size !== setB.size) return false;
  for (const a of setA) {
    if (!setB.has(a)) return false;
  }
  return true;
};

export const slug = (message: string): string =>
  slugify(message, {
    remove: /['.,?!@']/g,
    lower: true,
  }).toLowerCase();

export const splitAsHostPort = (host: string): [string, number] => {
  if (host.startsWith("http://") || host.startsWith("https://")) {
    host = host.split("://")[1]!;
  }
  const separatorIndex = host.lastIndexOf(":"); // Finding the last since IPv6 addr also has colons.
  if (separatorIndex <= 0 || separatorIndex + 1 === host.length) {
    throw new Error(`Invalid host ${host} with no separate hostname and port!`);
  }
  const port = parseInt(host.substring(separatorIndex + 1), 10);
  if (host[0] === "[") {
    // Bracket-quoted `[ipv6addr]:port` => return "ipv6addr" (without brackets).
    return [host.substring(1, separatorIndex - 1), port];
  } else {
    return [host.substring(0, separatorIndex), port];
  }
};

export const formatDuration = (value: Duration): string => {
  return [
    value.asHours() > 0 && `${Math.floor(value.asHours())}:`,
    value.asHours() > 0 ? value.minutes().toString().padStart(2, "0") : value.minutes().toString(),
    ":",
    value.seconds().toString().padStart(2, "0"),
  ]
    .filter(Boolean)
    .join("");
};

export const confirm = (message = "Are you sure?"): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (window.confirm(message)) {
      resolve();
    } else {
      reject("User cancelled the operation.");
    }
  });
};

export const buildUserContentTree = (
  user: User,
  content: Array<Category>,
  studyGroups: Array<StudyGroup>,
  tract: TractType,
  courseRegistrationId?: string,
): UserContentTree => {
  const tree: UserContentTree = { categories: [] };

  for (const category of content) {
    const topics: Array<{ topicId: string; title: Localized<string> }> = [];
    for (const topic of category.topics) {
      if (
        user.isContentManager() ||
        studyGroups.find(
          (sg, i) =>
            sg.categoryId === category.categoryId &&
            sg.topicId === topic.topicId &&
            user.activeContentAccess[i]!.tracts.includes(tract) &&
            (!courseRegistrationId || user.activeContentAccess[i]!.courseRegistrationId === courseRegistrationId),
        )
      ) {
        topics.push({ topicId: topic.topicId, title: topic.title });
      }
    }
    if (topics.length > 0) {
      tree.categories.push({ categoryId: category.categoryId, title: category.title, topics });
    }
  }

  return tree;
};

export const buildUserViews = (
  user: User | undefined,
  courseRegistrations: Array<CourseRegistration>,
  content: Array<Category>,
  studyGroups: Array<StudyGroup>,
): UserView[] => {
  const views: UserView[] = [];

  if (user) {
    if (user.isAdmin()) {
      views.push({
        role: "admin",
        modules: {
          quiz: buildUserContentTree(user, content, studyGroups, "quiz"),
          lector: true,
          video: { studyGroups },
          videoManager: true,
          studyMaterial: { studyGroups },
          admin: true,
        },
      });
    }

    if (user.isLector()) {
      views.push({
        role: "lector",
        modules: {
          quiz: buildUserContentTree(user, content, studyGroups, "quiz"),
          lector: true,
          video: { studyGroups },
          videoManager: false,
          studyMaterial: { studyGroups },
          admin: false,
        },
      });
    }

    if (user.isContentManager()) {
      views.push({
        role: "content-manager",
        modules: {
          quiz: false,
          lector: false,
          video: { studyGroups },
          videoManager: true,
          studyMaterial: false,
          admin: false,
        },
      });
    }

    for (const courseRegistration of courseRegistrations) {
      const studyGroupIds = user.activeContentAccess
        .filter((ca) => ca.courseRegistrationId === courseRegistration.courseRegistrationId && ca.studyGroupId)
        .map((ca) => ca.studyGroupId);
      const registrationStudyGroups = studyGroups.filter((sg) => studyGroupIds.includes(sg.studyGroupId));

      views.push({
        courseRegistration: courseRegistration,
        modules: {
          quiz: buildUserContentTree(user, content, studyGroups, "quiz", courseRegistration.courseRegistrationId),
          lector: false,
          video: registrationStudyGroups ? { studyGroups: registrationStudyGroups } : false,
          videoManager: false,
          studyMaterial: registrationStudyGroups ? { studyGroups: registrationStudyGroups } : false,
          admin: false,
        },
      });
    }

    const otherStudyGroupsPresent = user.activeContentAccess.map(
      (ca) => !courseRegistrations.some((cr) => cr.courseRegistrationId === ca.courseRegistrationId),
    );
    const otherStudyGroups = studyGroups.filter((_, i) => otherStudyGroupsPresent[i]);
    views.push({
      role: "user",
      modules: {
        quiz: buildUserContentTree(user, content, studyGroups, "quiz"),
        lector: false,
        video: { studyGroups: otherStudyGroups },
        videoManager: false,
        studyMaterial: { studyGroups: otherStudyGroups },
        admin: false,
      },
    });
  }

  if (views.length === 0) {
    views.push({
      role: "user",
      modules: {
        quiz: false,
        lector: false,
        video: false,
        videoManager: false,
        studyMaterial: false,
        admin: false,
      },
    });
  }

  return views;
};

export const userViewId = (userView: UserView) =>
  userView.role || userView.courseRegistration?.courseRegistrationId || "";

export const studyMaterialsLectures = (studyMaterials: StudyMaterial[], lectures?: number) => {
  const result: { lectureNumber: number; materials: StudyMaterial[] }[] = [];
  if (lectures) {
    for (let i = 1; i <= lectures; i++) {
      result.push({ lectureNumber: i, materials: [] });
    }
  }
  for (const studyMaterial of studyMaterials) {
    if (!lectures && !result[studyMaterial.lectureNumber - 1]) {
      result[studyMaterial.lectureNumber - 1] = { lectureNumber: studyMaterial.lectureNumber, materials: [] };
    }
    result[studyMaterial.lectureNumber - 1]!.materials.push(studyMaterial);
  }
  for (const studyMaterial of studyMaterials) {
    result[studyMaterial.lectureNumber - 1]!.materials.sort((a, b) => a.order - b.order);
  }
  return result;
};

export const searchEntries = <T extends object>(
  entries: Array<T>,
  search: string,
  searchFields: Array<string>,
): Array<T> => {
  const searcher = new Trove(entries, searchFields, {
    tokenize: true,
    normalizeLatinAccents: true,
    searchTreashold: 0.6,
    sort: true,
  });
  const scores = new Map<T, number>();

  const searchTokens = search.split(" ").filter((t) => t.length > 0);
  for (const searchToken of searchTokens) {
    const found = searcher.search(searchToken);
    for (let i = 0; i < found.length; i++) {
      const relScore = 5 / (i + 1);
      scores.set(found[i]!, (scores.get(found[i]!) || 0) + relScore);
    }
  }

  return Array.from(scores.entries())
    .sort((a, b) => b[1] - a[1])
    .map(([entry, _]) => entry);
};

export const searchPaginateRecords = <T extends object>(
  records: T[],
  search: string | undefined,
  limit: number,
  offset: number,
  searchFields: string[],
): T[] => {
  if (search) {
    records = searchEntries(records, search, searchFields);
  }
  return records.slice(offset, offset + limit);
};

export const reverseBootstrapColSize = (
  items: number,
  min: number = 3,
  max: number = 6,
): { size: number; offset: number } => {
  const size = Math.min(max, Math.max(min, Math.floor(12 / items)));
  const offset = Math.floor((12 - size * items) / 2);
  return { size, offset };
};

export const resolveDateRange = (dateRange: DateRange): { from: Moment; to: Moment } => {
  if (dateRange === "last-week") {
    return {
      from: moment.utc().startOf("day").subtract(1, "week"),
      to: moment.utc().startOf("day"),
    };
  } else if (dateRange === "last-month") {
    return {
      from: moment.utc().startOf("day").subtract(1, "month"),
      to: moment.utc().startOf("day"),
    };
  } else if (dateRange === "last-year") {
    return {
      from: moment.utc().startOf("day").subtract(1, "year"),
      to: moment.utc().startOf("day"),
    };
  } else {
    return dateRange;
  }
};
