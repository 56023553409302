import { IsOptional } from "class-validator";
import { WebEntry } from "./webEntry";
import { IsAsset, IsLocalizedMarkdown, IsLocalizedString } from "../helpers/decorators";
import { Transform } from "class-transformer";
import { transformLocalized, Localized } from "@namedicinu/internal-types";

export class StudyField extends WebEntry {
  public get type() {
    return "studyField" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  description: Localized<string>;
  @IsOptional()
  @IsAsset("image")
  image: string | null;
}
