import { plainToInstance } from "class-transformer";

import { User } from "@namedicinu/internal-types";

import useApp from "./useApp";

const defaultUser = plainToInstance(User, {
  email: "",
  name: "",
  role: "user",
  contentAccess: [],
  locale: "sk",
  timezone: "Europe/Bratislava",
});

const useUser = (): User => {
  const { user } = useApp();
  return user || defaultUser;
};

export default useUser;
