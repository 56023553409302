import { ClassConstructor, instanceToPlain, plainToInstance } from "class-transformer";
import { validate } from "class-validator";
import { JsonObject, JsonType } from "../types";

export async function instanceValidate<T extends object>(cotr: ClassConstructor<T>, data: any): Promise<T> {
  const instance = plainToInstance(cotr, data);
  const errors = await validate(instance, {
    whitelist: true,
    forbidNonWhitelisted: true,
  });
  if (errors.length > 0) {
    throw new Error(`${cotr.name} validation failed (${errors.length}): ${errors[0]!.toString()}`, { cause: errors });
  }
  return instance;
}

export async function instancesValidate<T extends object>(cotr: ClassConstructor<T>, data: any[]): Promise<T[]> {
  const instances: T[] = [];
  for (const datum of data) {
    instances.push(await instanceValidate(cotr, datum));
  }
  return instances;
}

export function plain<T extends object>(instance: T): JsonObject {
  return plainClean(instanceToPlain(instance)) as JsonObject;
}

// replace undefined with null
export function plainClean(obj: any): JsonType {
  if (obj === undefined) {
    return null;
  }
  if (Array.isArray(obj)) {
    return obj.map(plainClean);
  }
  if (obj !== null && typeof obj === "object") {
    const newObj: JsonObject = {};
    for (const key in obj) {
      newObj[key] = plainClean(obj[key]);
    }
    return newObj;
  }
  return obj;
}
