import { IsUUID, IsString, IsOptional } from "class-validator";
import { Transform } from "class-transformer";
import type { Moment } from "moment";

import { transformMoment } from "../helpers/transforms";
import { IsMoment, IsOptionalString } from "../helpers/decorators";

export class StudyGroup {
  @IsUUID()
  studyGroupId: string;
  @IsString()
  title: string;
  @IsOptional()
  @IsString()
  productId?: string;
  @IsOptional()
  @IsString()
  termId?: string;
  @IsString()
  categoryId: string;
  @IsString()
  topicId: string;
  @IsString()
  lector: string;
  @IsOptionalString()
  @IsString()
  description: string;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
  @Transform(transformMoment)
  @IsMoment()
  updatedAt: Moment;
}
