import { IsUUID, IsString, IsInt, IsBoolean } from "class-validator";
import type { Moment } from "moment";
import { Transform } from "class-transformer";

import { IsMoment } from "../helpers/decorators";
import { transformMoment } from "../helpers/transforms";

export class PublicQuizQuestionReport {
  @IsString()
  categoryId: string;
  @IsString()
  topicId: string;
  @IsInt()
  questionNumber: number;
  @IsString()
  message: string;
}

export class QuizQuestionReport extends PublicQuizQuestionReport {
  @IsUUID()
  quizQuestionReportId: string;
  @IsString()
  reporter: string;
  @Transform(transformMoment)
  @IsMoment()
  created: Moment;
  @IsBoolean()
  resolved: boolean;
}
