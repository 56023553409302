import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { Users } from "react-feather";

import DemoLayout from "./components/DemoLayout";

const DemoQuizPage = lazy(() => import("./pages/DemoQuizPage"));


export default {
  route: {
    path: "/demo",
    element: <DemoLayout />,
    children: [
      {
        index: true,
        path: "/demo/quiz",
        element: <DemoQuizPage />,
      },
    ]
  },
  name: <FormattedMessage id="module.lector.name" defaultMessage="Lector" />,
  icon: <Users />,
};
