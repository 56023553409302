import { TransformationType, TransformFnParams } from "class-transformer";
import moment from "moment";

import { Localized, LocaleType } from "@namedicinu/internal-types";

export const transformMoment = (params: TransformFnParams) => {
  const { value, type } = params;
  if (value === null || value === undefined) return value;

  if (type === TransformationType.PLAIN_TO_CLASS) {
    return moment(value);
  } else if (type === TransformationType.CLASS_TO_PLAIN) {
    return value.toISOString();
  } else if (type === TransformationType.CLASS_TO_CLASS) {
    return moment(value);
  } else {
    throw new Error("Invalid transformation type");
  }
};

export const transformMomentDate = (params: TransformFnParams) => {
  const { value, type } = params;
  if (value === null || value === undefined) return value;

  if (type === TransformationType.PLAIN_TO_CLASS) {
    return moment(value, "YYYY-MM-DD");
  } else if (type === TransformationType.CLASS_TO_PLAIN) {
    return value.format("YYYY-MM-DD");
  } else if (type === TransformationType.CLASS_TO_CLASS) {
    return moment(value);
  } else {
    throw new Error("Invalid transformation type");
  }
};

export const transformMomentDateEnd = (params: TransformFnParams) => {
  const { value, type } = params;
  if (value === null || value === undefined) return value;

  if (type === TransformationType.PLAIN_TO_CLASS) {
    return moment(value, "YYYY-MM-DD").endOf("day");
  } else if (type === TransformationType.CLASS_TO_PLAIN) {
    return value.format("YYYY-MM-DD");
  } else if (type === TransformationType.CLASS_TO_CLASS) {
    return moment(value);
  } else {
    throw new Error("Invalid transformation type");
  }
};

export const transformDuration = (params: TransformFnParams) => {
  const { value, type } = params;
  if (value === null || value === undefined) return value;

  if (type === TransformationType.PLAIN_TO_CLASS) {
    return moment.duration(value);
  } else if (type === TransformationType.CLASS_TO_PLAIN) {
    return value.toISOString();
  } else if (type === TransformationType.CLASS_TO_CLASS) {
    return moment.duration(value);
  } else {
    throw new Error("Invalid transformation type");
  }
};

export const transformTimestampMoment = (params: TransformFnParams) => {
  const { value, type } = params;
  if (value === null || value === undefined) return value;

  if (type === TransformationType.PLAIN_TO_CLASS) {
    if (typeof value == "object" && value.seconds !== undefined) {
      return moment.unix(value.seconds);
    } else {
      throw new Error("Invalid timestamp type");
    }
  } else if (type === TransformationType.CLASS_TO_PLAIN) {
    if (moment.isMoment(value)) {
      return value.toDate();
    }
  } else if (type === TransformationType.CLASS_TO_CLASS) {
    return moment(value);
  } else {
    throw new Error("Invalid transformation type");
  }
};

export const transformLocalized = (params: TransformFnParams) => {
  const { value, type } = params;
  if (value === null || value === undefined) return value;

  if (type === TransformationType.PLAIN_TO_CLASS) {
    const tValue = value as Record<LocaleType, any>;
    return new Localized(tValue);
  } else if (type === TransformationType.CLASS_TO_PLAIN) {
    const tValue = value as Localized<any>;
    return tValue.getData();
  } else if (type === TransformationType.CLASS_TO_CLASS) {
    const tValue = value as Localized<any>;
    return new Localized(tValue.getData());
  } else {
    throw new Error("Invalid transformation type");
  }
};
