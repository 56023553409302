import { FunctionComponent } from "react";
import { Container } from "reactstrap";
import { Outlet } from "react-router-dom";


const DemoLayout: FunctionComponent = ({}) => {
  
  return (
    <div className="page-wrapper">
      <Container fluid={true} className="d-flex align-items-center justify-content-center" style={{minHeight: "100vh"}}>
        <Outlet />
      </Container>
    </div>
  );
}

export default DemoLayout;