import { IsArray, IsString, Matches, ValidateNested } from "class-validator";
import { Transform, Type } from "class-transformer";
import { IsLocalizedString } from "../helpers/decorators";
import Localized from "../helpers/localized";
import { transformLocalized } from "../helpers/transforms";

export class Topic {
  @Matches(/^[a-z0-9\-]+$/)
  topicId: string;
  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
}

export class Category {
  @Matches(/^[a-z0-9\-]+$/)
  categoryId: string;
  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
  @IsArray()
  @Type(() => Topic)
  @ValidateNested({ each: true })
  topics: Topic[];
  @IsArray()
  @IsString({ each: true })
  quizQuestionSets: string[];
}
