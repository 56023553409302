export const webEntryTypes = [
  "blog",
  "faculty",
  "facultyField",
  "facultyStudents",
  "faq",
  "lecturer",
  "paymentFrequency",
  "product",
  "productVariation",
  "quizQuestion",
  "siteInformation",
  "studyField",
  "term",
  "testimonial",
] as const;
export type WebEntryType = (typeof webEntryTypes)[number];

export const assetTypes = {
  image: {
    allowedMimeTypes: ["image/jpeg", "image/png", "image/webp"],
  },
  document: {
    allowedMimeTypes: [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain",
    ],
  },
  config: {
    allowedMimeTypes: ["application/json", "application/yaml"],
  },
} as const;
export type AssetType = keyof typeof assetTypes;
