import { Transform } from "class-transformer";
import { IsArray, IsOptional, IsString } from "class-validator";
import type { Moment } from "moment";

import { IsMomentDate, transformMomentDate, transformMomentDateEnd } from "@namedicinu/internal-types";

import { WebEntry } from "./webEntry";
import { IsReference } from "../helpers/decorators";

export class Term extends WebEntry {
  public get type() {
    return "term" as const;
  }

  @IsString()
  identifier: string;
  @IsArray()
  @IsReference("product", { each: true })
  products: string[];
  @Transform(transformMomentDate)
  @IsMomentDate()
  registerStart: Moment;
  @Transform(transformMomentDateEnd)
  @IsMomentDate()
  registerEnd: Moment;
  @IsOptional()
  @Transform(transformMomentDate)
  @IsMomentDate()
  courseStart: Moment;
  @IsOptional()
  @Transform(transformMomentDateEnd)
  @IsMomentDate()
  courseEnd: Moment;
}
