import { locales, LocaleType } from "../types";

export default class Localized<T> implements Record<LocaleType, T | undefined> {
  data: Record<LocaleType, T | undefined>;

  constructor(data: Record<string, T>) {
    this.data = Object.fromEntries(locales.map((locale) => [locale, data[locale]])) as Record<
      LocaleType,
      T | undefined
    >;
  }

  has(locale: LocaleType) {
    return this.data[locale] !== undefined;
  }

  getData(): Record<string, T> {
    return Object.fromEntries(
      Object.entries(this.data)
        .map(([key, value]) => [key, value])
        .filter(([_, value]) => value !== undefined),
    );
  }

  getFilledLocales(): LocaleType[] {
    return Object.keys(this.data).filter((key) => this.data[key as LocaleType] !== undefined) as LocaleType[];
  }

  getValues(): T[] {
    return Object.values(this.data).filter((v) => v !== undefined) as T[];
  }

  get sk(): T | undefined {
    return this.data.sk;
  }

  get cs(): T | undefined {
    return this.data.cs;
  }

  get en(): T | undefined {
    return this.data.en;
  }
}
