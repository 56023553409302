import * as yup from "yup";
import { IsUUID, IsString, IsBoolean } from "class-validator";
import { JSONSchema } from "class-validator-jsonschema";
import type { Moment } from "moment";
import { Transform } from "class-transformer";

import { IsMoment, IsYup } from "../helpers/decorators";
import { transformMoment } from "../helpers/transforms";
import { yupRecord } from "../schemas/index";

export class QuizRelease {
  @IsUUID()
  quizReleaseId: string;
  @IsString()
  categoryId: string;
  @IsUUID()
  releaseTaskId: string;
  @IsYup(yupRecord(yup.string().required(), yup.string().required()))
  @JSONSchema({
    type: "object",
    description: "Quiz release encryption keys",
    additionalProperties: {
      type: "string",
    },
  })
  keys: Record<string, string>;
  @IsBoolean()
  published: boolean;
  @Transform(transformMoment)
  @IsMoment()
  created: Moment;
}

export class PublicQuizRelease {
  @IsString()
  quizReleaseId: string;
  @IsString()
  categoryId: string;
  @IsYup(yupRecord(yup.string().required(), yup.string().required()).required())
  @JSONSchema({
    type: "object",
    description: "Video encryption keys",
    additionalProperties: {
      type: "string",
    },
  })
  playbackKeys: Record<string, string>;
}
