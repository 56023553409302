import {
  FirebaseStorage,
  connectStorageEmulator,
  ref,
  getMetadata,
  StorageError,
  uploadBytesResumable,
  UploadTask,
  getDownloadURL,
} from "firebase/storage";
import fileDownload from "js-file-download";
import axios, { AxiosInstance } from "axios";
import { splitAsHostPort } from "../helpers/utils";
import { FileMetadata } from "./types";

export default class StorageClient {
  api: AxiosInstance;

  constructor(
    private readonly storageSource: FirebaseStorage,
    private readonly storagePublic: FirebaseStorage,
  ) {
    this.api = axios.create({
      baseURL: "/api",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  async connectAuth() {
    if (import.meta.env.VITE_FIREBASE_STORAGE_URL) {
      const [host, port] = splitAsHostPort(import.meta.env.VITE_FIREBASE_STORAGE_URL);
      const options = {
        mockUserToken: "owner",
      };
      connectStorageEmulator(this.storageSource, host, port, options);
      connectStorageEmulator(this.storagePublic, host, port, options);
    }
  }

  async inspectSourceFile(path: string): Promise<FileMetadata> {
    const file = ref(this.storageSource, path);
    try {
      const metadata = await getMetadata(file);
      return {
        exists: true,
        contentType: metadata.contentType || "",
        updated: metadata.updated || "",
        size: metadata.size ? metadata.size.toString() : "0",
      };
    } catch (e) {
      if (e instanceof StorageError && e.code === "storage/object-not-found") {
        return {
          exists: false,
        };
      } else {
        throw e;
      }
    }
  }

  async inspectPublicFile(path: string): Promise<FileMetadata> {
    const file = ref(this.storagePublic, path);
    try {
      const metadata = await getMetadata(file);
      return {
        exists: true,
        contentType: metadata.contentType || "",
        updated: metadata.updated || "",
        size: metadata.size ? metadata.size.toString() : "0",
      };
    } catch (e) {
      if (e instanceof StorageError && e.code === "storage/object-not-found") {
        return {
          exists: false,
        };
      } else {
        throw e;
      }
    }
  }

  uploadSourceFile(path: string, file: File): UploadTask {
    const fileRef = ref(this.storageSource, path);
    const uploadTask = uploadBytesResumable(fileRef, file, {
      contentType: file.type,
    });
    return uploadTask;
  }

  async downloadSourceFile(path: string, fileName?: string): Promise<void> {
    const fileRef = ref(this.storageSource, path);
    const downloadUrl = await getDownloadURL(fileRef);

    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName ? fileName : path.split("/").pop() || "file";
    a.click();
  }

  async uploadPublicFileIfMissing(path: string, file: Blob, onProgress?: (p: number) => void): Promise<void> {
    const fileRef = ref(this.storagePublic, path);
    const fileMetadata = await this.inspectPublicFile(path);
    if (!fileMetadata.exists) {
      return new Promise((resolve, reject) => {
        const metadata = {
          cacheControl: "public, max-age=31536000", // 1 year
          contentType: file.type,
        };
        const uploadTask = uploadBytesResumable(fileRef, file, metadata);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            if (onProgress) onProgress(progress);
          },
          (error) => {
            reject(error);
          },
          () => {
            resolve();
          },
        );
      });
    }
    return Promise.resolve();
  }

  async downloadPublicFile(path: string, fileName?: string): Promise<void> {
    const fileRef = ref(this.storagePublic, path);
    const downloadUrl = await getDownloadURL(fileRef);
    const res = await axios.get(downloadUrl, {
      responseType: "blob",
    });
    fileDownload(res.data, fileName ? fileName : path.split("/").pop() || "file");
  }

  getPublicUrl(path: string): string {
    return `${import.meta.env.VITE_PUBLIC_BUCKET_URL}${path}`;
  }
}
