import * as yup from "yup";
import type { Moment, Duration } from "moment";
import { Transform, Type } from "class-transformer";
import { Allow, IsBoolean, IsInt, IsOptional, IsString, IsUUID, ValidateNested } from "class-validator";

import { transformDuration, transformMoment, transformMomentDate } from "../helpers/transforms";
import { IsYup, IsDuration, IsMoment } from "../helpers/decorators";
import { yupRecord } from "../schemas/index";
import { JSONSchema } from "class-validator-jsonschema";
import { User } from "./user";

export class VideoBase {
  @IsUUID()
  videoId: string;
  @IsString()
  title: string;
  @IsOptional()
  @IsString()
  categoryId?: string;
  @IsOptional()
  @IsString()
  topicId?: string;
  @IsInt()
  lectureNumber: number;
  @Transform(transformDuration)
  @IsDuration()
  duration: Duration;
  @Transform(transformMomentDate)
  @IsMoment()
  timestamp: Moment;
}

export class Video extends VideoBase {
  @IsString()
  description: string;
  @IsOptional()
  @IsString()
  studyGroupId?: string;
  @IsOptional()
  @IsString()
  lectorId?: string;
  @IsBoolean()
  published: boolean;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
  @Transform(transformMoment)
  @IsMoment()
  updatedAt: Moment;
  @IsOptional()
  @IsYup(yupRecord(yup.string().required(), yup.string().required()).nullable())
  @JSONSchema({
    type: "object",
    description: "Video encryption keys",
    additionalProperties: {
      type: "string",
    },
  })
  keys: Record<string, string> | null;
  @IsOptional()
  @IsString()
  packageTaskId?: string;
  @IsOptional()
  @IsInt()
  size?: number;
  @IsOptional()
  @IsInt()
  thumbs?: number;
}

export class VideoPublicPlaybackLector {
  @IsString()
  firstName: string;
  @IsString()
  lastName: string;
  @IsOptional()
  @IsString()
  contactEmail?: string;
}

export class VideoPublicPlayback extends VideoBase {
  @IsString()
  description: string;
  @Transform(transformDuration)
  @IsDuration()
  watchOffset: Duration;
  @IsBoolean()
  attended: boolean;
  @IsYup(yupRecord(yup.string().required(), yup.string().required()).required())
  @JSONSchema({
    type: "object",
    description: "Video encryption keys",
    additionalProperties: {
      type: "string",
    },
  })
  playbackKeys: Record<string, string>;
  @IsOptional()
  @IsString()
  studyGroupId?: string;
  @IsOptional()
  @ValidateNested()
  @Type(() => VideoPublicPlaybackLector)
  lector?: VideoPublicPlaybackLector;
}

export class VideoPublic extends VideoBase {
  @Transform(transformDuration)
  @IsDuration()
  watchOffset: Duration;
  @IsBoolean()
  attended: boolean;
  @IsInt()
  thumbs: number;
}

export class UserVideoWatch {
  @Allow()
  user: User;

  @Transform(transformDuration)
  @IsDuration()
  watchOffset: Duration;
  @IsBoolean()
  attended: boolean;
}
