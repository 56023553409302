import { Transform } from "class-transformer";
import { IsInt, IsOptional, IsString } from "class-validator";

import type { JsonArray, TractType } from "@namedicinu/internal-types";

import { IsLocalizedInt } from "../helpers/decorators";

import { WebEntry } from "./webEntry";
import { IsLocalized, Localized, transformLocalized } from "@namedicinu/internal-types";
import { IsLocalizedInfoPanel, IsLocalizedMarkdown, IsLocalizedString } from "../helpers/decorators";

export type ProductSubjects = Array<{
  title: string;
  category: "A" | "B" | "C" | "D" | "E";
  unlockCategoryId: string;
  unlockTopicId: string;
}>;

export class Product extends WebEntry {
  public get type() {
    return "product" as const;
  }

  @Transform(transformLocalized)
  @IsLocalizedString()
  title: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  description: Localized<string>;
  @IsOptional()
  @Transform(transformLocalized)
  @IsLocalizedInfoPanel()
  descriptionPanel?: Localized<JsonArray>;
  @IsOptional()
  @Transform(transformLocalized)
  @IsLocalizedString()
  action: Localized<string>;
  @IsOptional()
  @Transform(transformLocalized)
  @IsLocalizedString()
  actionName: Localized<string>;
  @Transform(transformLocalized)
  @IsLocalized({
    type: "array",
    items: {
      type: "object",
      properties: {
        categoryCombination: { type: "string" },
        price: { type: "integer" },
        deposit: { type: "integer" },
        discount: { type: "integer" },
        monthly: { type: "integer" },
      },
      required: ["categoryCombination", "price", "deposit", "discount", "monthly"],
    },
  })
  price: Localized<
    {
      categoryCombination: string;
      price: number;
      deposit: number;
      discount: number;
      monthly: number;
    }[]
  >;
  @Transform(transformLocalized)
  @IsOptional()
  @IsLocalizedInt()
  priceMin?: Localized<number>;
  @Transform(transformLocalized)
  @IsOptional()
  @IsLocalizedInt()
  priceMax?: Localized<number>;
  @Transform(transformLocalized)
  @IsLocalized({
    type: "object",
    properties: {
      default: {
        type: "array",
        items: {
          type: "object",
          properties: {
            title: { type: "string" },
            category: { type: "string", enum: ["A", "B", "C", "D"] },
            unlockCategoryId: { type: "string" },
            unlockTopicId: { type: "string" },
          },
          required: ["title"],
        },
      },
      faculties: {
        type: "array",
        items: {
          type: "object",
          properties: {
            faculty: { type: "string", format: "referenceId:faculty" },
            subjects: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  title: { type: "string" },
                  category: { type: "string", enum: ["A", "B", "C", "D", "E"] },
                  unlockCategoryId: { type: "string" },
                  unlockTopicId: { type: "string" },
                },
                required: ["title"],
              },
            },
          },
        },
      },
      unlockTracts: {
        type: "array",
        items: {
          type: "string",
        },
      },
    },
    required: ["default"],
  })
  subjects: Localized<{
    default: ProductSubjects;
    faculties?: Array<{
      faculty: string;
      subjects: ProductSubjects;
    }>;
    unlockTracts?: TractType[];
  }>;
  @IsOptional()
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  priceDescription?: Localized<string>;
  @IsOptional()
  @Transform(transformLocalized)
  @IsLocalizedString()
  registerTitle?: Localized<string>;
  @IsOptional()
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  registerDescription?: Localized<string>;
  @IsOptional()
  @Transform(transformLocalized)
  @IsLocalizedMarkdown()
  registerDescriptionOpen?: Localized<string>;
  @IsOptional()
  @IsString()
  icon?: string;
  @IsOptional()
  @IsInt()
  iconCount?: number;
}
