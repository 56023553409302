import { FunctionComponent } from "react";

const Loader: FunctionComponent = () => {
  
  return (
    <div className="loader-wrapper">
      <div className="typewriter">
        <span>Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
