import { Transform } from "class-transformer";
import { IsEnum, IsOptional, IsString, IsUUID } from "class-validator";
import moment, { type Moment, type Duration } from "moment";

import { IsMoment } from "../helpers/decorators";
import { transformMoment } from "../helpers/transforms";
import { taskStates, type TaskState, taskTypes, type TaskType } from "../types";

export class Task {
  @IsUUID()
  taskId: string;
  @IsEnum(taskTypes)
  type: TaskType;
  @IsEnum(taskStates)
  state: TaskState;
  @IsOptional()
  @IsString()
  message?: string;
  @IsOptional()
  @IsString()
  error?: string;
  @Transform(transformMoment)
  @IsMoment()
  created: Moment;
  @Transform(transformMoment)
  @IsMoment()
  lastUpdate: Moment;

  public get runtime(): Duration {
    if (this.state === "pending" || this.state === "running") {
      return moment.duration(moment().diff(this.created, "seconds"), "seconds");
    } else {
      return moment.duration(this.lastUpdate.diff(this.created, "seconds"), "seconds");
    }
  }
}
